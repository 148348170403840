<template>
  <div class="inform">
    <div class="inform_top">
      <div class="left">
        <img :src="cancel" @click="toPage('PickInform')" alt />
      </div>
      <div class="center">固定接送人</div>
    </div>
    <div class="inform_center">
      <ul>
        <li @click="toPage('UploadFace')">
          <p>人脸照片</p>
          <p>
            <img :src="right" alt />
          </p>
        </li>
        <li @click="toPage('PickAddUserInfo')">
          <p>基本信息</p>
          <p>
            <img :src="right" alt />
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import right from "@/assets/right.png";
export default {
  data() {
    return {
      cancel,
      right,
      status: "",
      id: "",
    };
  },
  created() {
    this.status = this.$route.query.status
      ? Number(this.$route.query.status)
      : "";
    this.id = this.$route.query.id ? Number(this.$route.query.id) : "";
  },
  methods: {
    toPage(name) {
      if (name === "PickInform") {
        this.$router.push({ name });
        return;
      }
      if (this.status === 0 || this.status === 1) {
        this.$router.push({
          name,
          query: { status: JSON.stringify(this.status) },
        });
      } else if (this.id) {
        this.$router.push({ name, query: { id: JSON.stringify(this.id) } });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.inform {
  .inform_top {
    height: 48px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #ddd;
    .left {
      position: absolute;
      left: 1rem;
      img {
        // width: 35px;
        height: 30px;
      }
    }
  }
  .inform_center {
    background: #fff;
    margin: 0 auto;
    margin-top: 10px;
    width: 95%;
    border-radius: 5px;
    color: #333333;
    ul {
      li {
        padding: 15px 5px 15px 1rem;
        display: flex;
        justify-content: space-between;
        p {
          margin: 0;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>